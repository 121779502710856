@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
	font-family: "Lato", sans-serif;
}

// .ant-btn.custom-btn {
// 	display: flex;
// 	justify-content: center;
// 	width: 100%;
// 	padding: 0.5rem 1rem 1.8rem;
// 	font-size: 0.875rem;
// 	line-height: 1.25rem;
// 	border-radius: 1rem;
// 	font-weight: 500;
// 	font-family: "Poppins";
// 	color: #ffffff !important;
// 	background: #0071c5 !important;
// 	transition: all 0.4s;

// 	&:hover,
// 	&:focus,
// 	&:active {
// 		border: 1px solid transparent !important;
// 		background: #0071c5 !important;
// 		outline: none !important;
// 		box-shadow: none !important;
// 		color: inherit;
// 	}
// }

.linear-bg-one {
	background: rgb(238, 174, 202);
	background: radial-gradient(circle, rgba(238, 174, 202, 0.5) 0%, rgba(81, 146, 223, 0.5) 100%);
}

.dashboard-card-home {
	&.ant-card {
		.ant-card-body {
			padding: 0px !important;
			height: 100px !important;
		}
	}
}

.ant-timeline-item-head.ant-timeline-item-head-custom.ant-timeline-item-head-blue {
	background: transparent !important;
}

.nav-item {
	position: relative;
	width: fit-content;

	&.active {
		&:after {
			content: "";
			height: 0.15rem;
			width: 100%;
			background: #0071c5;
			position: absolute;
			bottom: -0.1rem;
			left: 0;
		}
	}
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes fade-in-down {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fade-in-up {
	0% {
		opacity: 0;
		transform: translateY(10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.animate-fade-in-down {
	animation: fade-in-down 0.5s ease-out;
}

.animate-fade-in-up {
	animation: fade-in-up 0.5s ease-out;
}
